@import '../../styles/variables.scss';

.Button {
  // position: absolute;
  // top: 5px;
  // right: 5px;
  border: 1px solid;
  background: transparent;
  color: white;
  padding: 1rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    background: gray;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background: $color-primary
  }

  &.Button-primary {
    background: $color-primary;  
  }
}