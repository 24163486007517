@import "../../styles/variables.scss";

.BuyTicketPage {
  display: flex;
  min-height: $content-height-min;

  & > section:first-child {
    flex: 1;
  }
  & > section:nth-child(2) {
    width: 300px;
  }

  .BuyTicket_Buttons {
    margin-top: 2rem;
  }
}
