@import "../../styles/variables.scss";

.SingleMovie {
  margin-bottom: -2 * $padding-default;

  .SingleMovie_Introduction {
    display: flex;
    position: relative;
    height: calc(100vh - #{$size-navBar-height} - 8rem);
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 30%;
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9136029411764706) 0%,
        rgba(0, 0, 0, 0.8435749299719888) 31%,
        rgba(0, 0, 0, 0.41780462184873945) 67%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    & > .Image {
      position: absolute;
    }

    .Introduction_Poster {
      z-index: 10;
      width: 33%;
      position: relative;

      .Image {
        height: unset;
        position: absolute;
        bottom: $padding-default * 2;
        width: 100%;

        img {
          object-fit: contain;
          width: 60%;
          margin: auto;
          box-shadow: 4px 9px 40px #000000a8;
        }
      }
    }

    .Introduction_Title {
      width: 66%;
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      h1 {
        font-size: 2.5rem;
        margin-bottom: $padding-default * 2;
      }
    }
  }

  .SingleMovie_Info {
    max-width: $content-width-max;
    margin: auto;
    text-align: left;
    padding: $padding-default;

    h3 {
      margin-bottom: 0.25rem;
    }
  }
}

.Item_Details {
  &:hover {
    h3 {
      color: $color-primary;
    }
  }
}

.Seances {
  margin-top: 2 * $padding-default;
  .Seances_Hour {
    justify-content: space-between;
    display: flex;

    &:hover {
      p {
        color: $color-primary;
      }
    }
  }

  .ytp-show-cards-title,
  .ytp-title-channel {
    opacity: 0.5;
  }
}

.SingleMovie_VideoContainer {
  height: 670px;
  overflow: hidden;

  .SingleMovie_Iframe {
    position: relative;
    top: -70px;
    width: 100%;
    height: 740px;
    // opacity: 0.5;
  }
}
