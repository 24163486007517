@import "./styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap");

body {
  box-sizing: border-box;
  *,
  &:before,
  &:after {
    box-sizing: border-box;
  }
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-background;
}

button,
select {
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $text-color-default;

  &:hover {
    color: $color-primary;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}
