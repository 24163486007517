@import "../../styles/variables.scss";

.AllMovies {
  max-width: $content-width-max;
  padding-top: $padding-default;
  margin: auto;
  min-height: $content-height-min;

  .AllMovies_Header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $padding-default * 2.5;

    h1 {
      margin-bottom: 0;
    }

    .AllMovies_Search {
      .Search_Label {
        span {
          display: block;
        }
      }

      .Search_Input {
        background: transparent;
        border: none;
        border-bottom: 2px solid white;
        font-size: 1.5rem;
        color: white;
        padding: 0 0.5rem;
        width: 230px;

        &:focus {
          outline: none;
          background: rgba(white, 0.1);
        }
      }

      .Search_Clear {
        padding: 0 0.5rem;
        height: 2rem;
        margin-left: 0.5rem;
        position: relative;
        top: -2px;
      }
    }
  }

  .AllMovies_List {
    margin: 0 auto;
  }

  .AllMovies_Item {
    display: flex;
    height: 150px;
    margin-bottom: 1rem;

    .Image {
      width: 100px;
    }
  }
  .Item_Details {
    text-align: left;
    margin-left: 1rem;
  }

  .Search_Clear {
    img {
      height: 1rem;
    }
  }
}

@media only screen and (max-width: $media-width-mobile-max) {
  .AllMovies {
    padding-left: 1rem;
    padding-right: 1rem;

    .Search_Input {
      width: calc(100% - 4rem) !important;
      margin-top: 1rem;
    }
  }
}
