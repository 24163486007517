@import "../../styles/variables.scss";

.MovieImage {
  height: 100%;
  position: relative;

  .MovieImage_Title {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    margin: 0;
    width: 100%;
    font-weight: bold;
    transition: $animation-default;
  }

  &.MovieImage-large {
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 30%;
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9136029411764706) 0%,
        rgba(0, 0, 0, 0.8435749299719888) 31%,
        rgba(0, 0, 0, 0.41780462184873945) 67%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .MovieImage_Title {
      width: calc(100% - 2rem);
      font-size: 1.5rem;
    }
  }

  &.MovieImage-medium {
    .MovieImage_Title {
      font-size: 0.8rem;
      font-size: 0.8rem;
      position: static;
      margin-top: 0.25rem;
    }
  }
}
