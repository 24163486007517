@import "../../../../styles/variables.scss";

.SelectSeatsStep {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .SelectSeatsStep_Container {
    padding: $padding-default;
    width: 430px;

    .SelectSeatsStep_Screen {
      margin-bottom: 4rem;
      padding: 4px;
      text-align: center;
      margin-top: 3rem;
      border-top: 3px solid white;
    }
  }

  .SeatSelector {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 60px);
    grid-template-rows: repeat(5, 60px);
    grid-auto-flow: row;

    label.SeatSelector_Seat {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      cursor: pointer;
      font-size: 1.25rem;
      font-weight: bold;

      .SeatSelector_Chair {
        position: absolute;
        height: 100%;
        width: 100%;
        border: 2px solid white;
        z-index: -1;
        border-radius: 50%;
      }

      &:hover .SeatSelector_Chair {
        // background: white;
        opacity: 0.8;
      }

      input {
        display: none;

        &:checked ~ .SeatSelector_Chair {
          border-color: rgb(95, 140, 5);
          border-width: 4px;
        }
        &:disabled ~ .SeatSelector_Chair {
          border-color: $color-primary;
          border-width: 4px;
          cursor: not-allowed;
        }
      }
    }
  }
}
