.Modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #501212c4;
  top: 0;

  .Modal_Inner {
    position: relative;
    width: 500px;
    box-shadow: 0 0 30px black;
    background: black;
    padding: 2rem;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Modal_Title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    .Modal_Content {
      margin-bottom: 2rem;
    }

    .Modal_Footer {
      width: 100%;
    }
  }
}
