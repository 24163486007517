.Image {
  width: 100%;
  height: 100%;

  img {
  width: 100%;
  height: 100%;
    object-fit: cover;
    display: block;
  }
}
