// COLOR VARIABLES
$color-background: black;
$color-primary: #bf2a2a;
$text-color-default: white;
$text-color-black: #000;

// MEDIA VARIABLES
$media-width-mobile-max: 500px;

// SIZE VARIABLES
$padding-default: 2rem;
$content-width-max: 800px;
$content-height-min: 84vh;

// ELEMENT SIZE VARIABLES
$size-navBar-height: 55px;

// ANIMATION
$animation-default: all 300ms;
