@import "../../styles//variables.scss";

.MainPage {
  padding-top: 0;

  .Section_Container {
    max-width: 1200px;
    padding: 0 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }

  .Section_Header {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  .Section_Text {
    font-size: 1.25rem;
  }
}
