@import "../../styles/variables.scss";

.MovieSlider {
  margin-top: $padding-default * 2;

  .MovieSlider_Title {
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  .MovieSlider_List {
    display: flex;
    height: 100%;
  }

  &.MovieSlider-large {
    margin-top: 0;
    height: 60vh;

    .MovieSlider_Image {
      flex: 1;
    }
  }

  &.MovieSlider-medium {
    overflow: hidden;
    max-width: $content-width-max * 1.5;
    margin-left: auto;
    margin-right: auto;

    .MovieSlider_Image {
      // flex: 1
      margin: 1rem;
      margin-bottom: 1.5rem;

      // .Image {
      //   img {
      //     object-fit: contain;
      //   }
      // }
    }
  }
}

@media only screen and (max-width: $media-width-mobile-max) {
  .MovieSlider {
    &.MovieSlider-large {
      height: unset;

      .MovieSlider_List {
        height: unset;
        flex-direction: column;

        .MovieSlider_Image {
          height: 80vh;
        }
      }
    }

    &.MovieSlider-medium {
      height: unset;

      .MovieSlider_List {
        flex-wrap: wrap;

        .MovieSlider_Image {
          max-width: 40%;
        }
      }
    }
  }
}
