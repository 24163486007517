@import "../../styles/variables.scss";

.success {
  text-align: center;

  h2 {
    color: seagreen;
  }

  a {
    display: block;
  }
}

.CheckoutForm {
  border: 1px solid;
  padding: 2rem;
  width: 400px;

  span {
    color: $text-color-default;
  }

  p {
    text-align: center;
  }

  label {
    min-width: 500px;
    color: $text-color-default;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    color: $text-color-black;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    width: 100%;

    &::placeholder {
      color: #aab7c4;
    }

    &:focus,
    .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }
  }

  .order-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    background-color: #bf2a2a;
    border: 1px solid #bf2a2a;
    font-size: 1em;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    padding: 0.5em 1em;
    width: 200px;
    transition: opacity 200ms;
    margin-top: 3rem;
    cursor: pointer;

    &.success {
      background-color: seagreen;
      border-color: seagreen;
    }

    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  a {
    display: block;
    text-align: center;
    margin-top: 1em;
  }
}

span.Message-error {
  color: red;
  margin-top: 1rem;
  display: block;
}
