@import "../../styles/variables.scss";

.NavBar {
  // position: fixed;
  // width: 100vw;
  display: flex;
  height: $size-navBar-height;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background: $color-primary;
  color: $text-color-default;
  position: relative;

  .NavBar_Logo {
    align-items: center;
    display: flex;
    font-size: 1rem;
    margin-left: 2rem;
    font-weight: bold;
    transition: $animation-default;

    &:hover {
      opacity: 0.7;
      color: white !important;
    }

    img {
      position: absolute;
      left: 1rem;
      height: 1.5rem;
    }
  }

  .NavBar_List {
    display: flex;
    justify-content: space-between;
    list-style: none;
    // text-transform: capitalize;

    .NavBar_Link {
      font-size: 1rem;
      color: $text-color-default;
      background: none;
      border: none;
      padding: 0.25rem;
      cursor: pointer;
      transition: $animation-default;
      display: inline-block;
      font-weight: bold;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        transition: opacity 0;
        opacity: 1;
        color: $text-color-black;
      }

      option {
        color: $text-color-black;
      }
    }

    li {
      padding: 0;
      & + li {
        margin-left: 1rem;
      }
    }
  }
}
