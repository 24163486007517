@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap);
body{box-sizing:border-box;margin:0;font-family:"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#000}body *,body:before,body:after{box-sizing:border-box}button,select{font-family:"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif}ul{list-style:none;margin:0;padding:0}a{text-decoration:none;color:#fff}a:hover{color:#bf2a2a}h1,h2,h3,h4,h5,h6,p{margin-top:0}h1{font-size:2.5rem}

.App{background-color:#000;height:100vh;color:#fff;overflow:hidden}.App .App_Content{height:calc(100vh - 55px);overflow-y:auto}

.MovieImage{height:100%;position:relative}.MovieImage .MovieImage_Title{position:absolute;bottom:1rem;left:1rem;margin:0;width:100%;font-weight:bold;transition:all 300ms}.MovieImage.MovieImage-large::before{content:"";display:block;position:absolute;bottom:0;height:30%;width:100%;background:linear-gradient(0deg, rgba(0,0,0,0.913603) 0%, rgba(0,0,0,0.843575) 31%, rgba(0,0,0,0.417805) 67%, rgba(0,0,0,0) 100%)}.MovieImage.MovieImage-large .MovieImage_Title{width:calc(100% - 2rem);font-size:1.5rem}.MovieImage.MovieImage-medium .MovieImage_Title{font-size:0.8rem;font-size:0.8rem;position:static;margin-top:0.25rem}

.Image{width:100%;height:100%}.Image img{width:100%;height:100%;object-fit:cover;display:block}

.MovieSlider{margin-top:4rem}.MovieSlider .MovieSlider_Title{margin-left:1rem;font-size:2rem;font-weight:bold;margin-bottom:0}.MovieSlider .MovieSlider_List{display:flex;height:100%}.MovieSlider.MovieSlider-large{margin-top:0;height:60vh}.MovieSlider.MovieSlider-large .MovieSlider_Image{flex:1 1}.MovieSlider.MovieSlider-medium{overflow:hidden;max-width:1200px;margin-left:auto;margin-right:auto}.MovieSlider.MovieSlider-medium .MovieSlider_Image{margin:1rem;margin-bottom:1.5rem}@media only screen and (max-width: 500px){.MovieSlider.MovieSlider-large{height:unset}.MovieSlider.MovieSlider-large .MovieSlider_List{height:unset;flex-direction:column}.MovieSlider.MovieSlider-large .MovieSlider_List .MovieSlider_Image{height:80vh}.MovieSlider.MovieSlider-medium{height:unset}.MovieSlider.MovieSlider-medium .MovieSlider_List{flex-wrap:wrap}.MovieSlider.MovieSlider-medium .MovieSlider_List .MovieSlider_Image{max-width:40%}}

.MainPage{padding-top:0}.MainPage .Section_Container{max-width:1200px;padding:0 1rem;margin-left:auto;margin-right:auto;margin-top:4rem}.MainPage .Section_Header{font-size:2rem;margin-bottom:0.75rem}.MainPage .Section_Text{font-size:1.25rem}

.NavBar{display:flex;height:55px;top:0;justify-content:space-between;align-items:center;padding:0 1rem;background:#bf2a2a;color:#fff;position:relative}.NavBar .NavBar_Logo{align-items:center;display:flex;font-size:1rem;margin-left:2rem;font-weight:bold;transition:all 300ms}.NavBar .NavBar_Logo:hover{opacity:0.7;color:white !important}.NavBar .NavBar_Logo img{position:absolute;left:1rem;height:1.5rem}.NavBar .NavBar_List{display:flex;justify-content:space-between;list-style:none}.NavBar .NavBar_List .NavBar_Link{font-size:1rem;color:#fff;background:none;border:none;padding:0.25rem;cursor:pointer;transition:all 300ms;display:inline-block;font-weight:bold}.NavBar .NavBar_List .NavBar_Link:hover{opacity:0.7}.NavBar .NavBar_List .NavBar_Link:active{transition:opacity 0;opacity:1;color:#000}.NavBar .NavBar_List .NavBar_Link option{color:#000}.NavBar .NavBar_List li{padding:0}.NavBar .NavBar_List li+li{margin-left:1rem}

.SingleMovie{margin-bottom:-4rem}.SingleMovie .SingleMovie_Introduction{display:flex;position:relative;height:calc(100vh - 55px - 8rem)}.SingleMovie .SingleMovie_Introduction::after{content:"";display:block;position:absolute;bottom:0;height:30%;width:100%;background:linear-gradient(0deg, rgba(0,0,0,0.913603) 0%, rgba(0,0,0,0.843575) 31%, rgba(0,0,0,0.417805) 67%, rgba(0,0,0,0) 100%)}.SingleMovie .SingleMovie_Introduction>.Image{position:absolute}.SingleMovie .SingleMovie_Introduction .Introduction_Poster{z-index:10;width:33%;position:relative}.SingleMovie .SingleMovie_Introduction .Introduction_Poster .Image{height:unset;position:absolute;bottom:4rem;width:100%}.SingleMovie .SingleMovie_Introduction .Introduction_Poster .Image img{object-fit:contain;width:60%;margin:auto;box-shadow:4px 9px 40px #000000a8}.SingleMovie .SingleMovie_Introduction .Introduction_Title{width:66%;position:relative;z-index:10;display:flex;justify-content:flex-start;align-items:flex-end}.SingleMovie .SingleMovie_Introduction .Introduction_Title h1{font-size:2.5rem;margin-bottom:4rem}.SingleMovie .SingleMovie_Info{max-width:800px;margin:auto;text-align:left;padding:2rem}.SingleMovie .SingleMovie_Info h3{margin-bottom:0.25rem}.Item_Details:hover h3{color:#bf2a2a}.Seances{margin-top:4rem}.Seances .Seances_Hour{justify-content:space-between;display:flex}.Seances .Seances_Hour:hover p{color:#bf2a2a}.Seances .ytp-show-cards-title,.Seances .ytp-title-channel{opacity:0.5}.SingleMovie_VideoContainer{height:670px;overflow:hidden}.SingleMovie_VideoContainer .SingleMovie_Iframe{position:relative;top:-70px;width:100%;height:740px}

.Button{border:1px solid;background:transparent;color:white;padding:1rem;cursor:pointer}.Button:disabled{opacity:0.5;background:gray;cursor:default}.Button:hover:not(:disabled){background:#bf2a2a}.Button.Button-primary{background:#bf2a2a}

.AllMovies{max-width:800px;padding-top:2rem;margin:auto;min-height:84vh}.AllMovies .AllMovies_Header{display:flex;justify-content:space-between;flex-wrap:wrap;align-items:center;margin-bottom:5rem}.AllMovies .AllMovies_Header h1{margin-bottom:0}.AllMovies .AllMovies_Header .AllMovies_Search .Search_Label span{display:block}.AllMovies .AllMovies_Header .AllMovies_Search .Search_Input{background:transparent;border:none;border-bottom:2px solid white;font-size:1.5rem;color:white;padding:0 0.5rem;width:230px}.AllMovies .AllMovies_Header .AllMovies_Search .Search_Input:focus{outline:none;background:rgba(255,255,255,0.1)}.AllMovies .AllMovies_Header .AllMovies_Search .Search_Clear{padding:0 0.5rem;height:2rem;margin-left:0.5rem;position:relative;top:-2px}.AllMovies .AllMovies_List{margin:0 auto}.AllMovies .AllMovies_Item{display:flex;height:150px;margin-bottom:1rem}.AllMovies .AllMovies_Item .Image{width:100px}.AllMovies .Item_Details{text-align:left;margin-left:1rem}.AllMovies .Search_Clear img{height:1rem}@media only screen and (max-width: 500px){.AllMovies{padding-left:1rem;padding-right:1rem}.AllMovies .Search_Input{width:calc(100% - 4rem) !important;margin-top:1rem}}

.Modal{position:fixed;display:flex;align-items:center;justify-content:center;width:100vw;height:100vh;z-index:100;background:#501212c4;top:0}.Modal .Modal_Inner{position:relative;width:500px;box-shadow:0 0 30px black;background:black;padding:2rem;min-height:250px;display:flex;flex-direction:column;justify-content:space-between}.Modal .Modal_Inner .Modal_Title{font-size:1.5rem;font-weight:bold;margin-bottom:1.5rem}.Modal .Modal_Inner .Modal_Content{margin-bottom:2rem}.Modal .Modal_Inner .Modal_Footer{width:100%}

.CinemaSelection_Input{display:block;margin-bottom:0.25rem}

.Ticket{background:white;padding:2rem;margin-bottom:-4rem;color:#000}

.SelectSeatsStep{display:flex;flex-direction:column;justify-content:center;align-items:center}.SelectSeatsStep .SelectSeatsStep_Container{padding:2rem;width:430px}.SelectSeatsStep .SelectSeatsStep_Container .SelectSeatsStep_Screen{margin-bottom:4rem;padding:4px;text-align:center;margin-top:3rem;border-top:3px solid white}.SelectSeatsStep .SeatSelector{display:grid;grid-gap:1rem;grid-template-columns:repeat(5, 60px);grid-template-rows:repeat(5, 60px);grid-auto-flow:row}.SelectSeatsStep .SeatSelector label.SeatSelector_Seat{width:100%;height:100%;display:flex;align-items:center;justify-content:center;position:relative;z-index:1;cursor:pointer;font-size:1.25rem;font-weight:bold}.SelectSeatsStep .SeatSelector label.SeatSelector_Seat .SeatSelector_Chair{position:absolute;height:100%;width:100%;border:2px solid white;z-index:-1;border-radius:50%}.SelectSeatsStep .SeatSelector label.SeatSelector_Seat:hover .SeatSelector_Chair{opacity:0.8}.SelectSeatsStep .SeatSelector label.SeatSelector_Seat input{display:none}.SelectSeatsStep .SeatSelector label.SeatSelector_Seat input:checked ~ .SeatSelector_Chair{border-color:#5f8c05;border-width:4px}.SelectSeatsStep .SeatSelector label.SeatSelector_Seat input:disabled ~ .SeatSelector_Chair{border-color:#bf2a2a;border-width:4px;cursor:not-allowed}

.success{text-align:center}.success h2{color:seagreen}.success a{display:block}.CheckoutForm{border:1px solid;padding:2rem;width:400px}.CheckoutForm span{color:#fff}.CheckoutForm p{text-align:center}.CheckoutForm label{min-width:500px;color:#fff;font-weight:300;letter-spacing:0.025em}.CheckoutForm input,.CheckoutForm .StripeElement{display:block;margin:10px 0 20px 0;padding:10px 14px;font-size:1em;color:#000;box-shadow:rgba(50,50,93,0.14902) 0px 1px 3px,rgba(0,0,0,0.0196078) 0px 1px 0px;border:0;outline:0;border-radius:4px;background:white;width:100%}.CheckoutForm input::-webkit-input-placeholder, .CheckoutForm .StripeElement::-webkit-input-placeholder{color:#aab7c4}.CheckoutForm input:-ms-input-placeholder, .CheckoutForm .StripeElement:-ms-input-placeholder{color:#aab7c4}.CheckoutForm input::-ms-input-placeholder, .CheckoutForm .StripeElement::-ms-input-placeholder{color:#aab7c4}.CheckoutForm input::placeholder,.CheckoutForm .StripeElement::placeholder{color:#aab7c4}.CheckoutForm input:focus,.CheckoutForm input .StripeElement--focus,.CheckoutForm .StripeElement:focus,.CheckoutForm .StripeElement .StripeElement--focus{box-shadow:rgba(50,50,93,0.109804) 0px 4px 6px,rgba(0,0,0,0.0784314) 0px 1px 3px;transition:all 150ms ease}.CheckoutForm .order-button{display:flex;align-items:center;justify-content:center;margin:0 auto;text-decoration:none;background-color:#bf2a2a;border:1px solid #bf2a2a;font-size:1em;font-weight:bold;color:white;border-radius:5px;padding:0.5em 1em;width:200px;transition:opacity 200ms;margin-top:3rem;cursor:pointer}.CheckoutForm .order-button.success{background-color:seagreen;border-color:seagreen}.CheckoutForm .order-button:hover{opacity:0.8}.CheckoutForm .order-button:disabled{opacity:0.5;cursor:not-allowed}.CheckoutForm a{display:block;text-align:center;margin-top:1em}span.Message-error{color:red;margin-top:1rem;display:block}

.CheckoutStep{display:flex;flex-direction:column;justify-content:center;align-items:center}

.ThankYouStep{max-width:600px;margin:auto}

.BuyTicketPage{display:flex;min-height:84vh}.BuyTicketPage>section:first-child{flex:1 1}.BuyTicketPage>section:nth-child(2){width:300px}.BuyTicketPage .BuyTicket_Buttons{margin-top:2rem}

.Footer{padding:2rem;padding-bottom:1rem;box-shadow:0 -26px 72px #202020;margin-top:4rem;font-size:75%;border-bottom:4px solid #bf2a2a}.Footer .Footer_Container{max-width:800px;margin:auto;display:flex;justify-content:space-between;flex-wrap:wrap}.Footer .Footer_CinemaName{font-size:125%;font-weight:bold;color:#bf2a2a;margin-bottom:0.5rem}

